const MODULE_NAME = 'formStatus'
export const SET_FORM_STATUS = `${MODULE_NAME}/SET_FORM_STATUS`

export const updateFormStatus = value => dispatch => {
  dispatch({
    type: SET_FORM_STATUS,
    payload: { isFormChanged: value },
  })
  return Promise.resolve()
}
