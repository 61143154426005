import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import Spinner from 'apps/PageSpinner'

const AuthModule = React.lazy(() => import('modules/Auth'))
const AdminModule = React.lazy(() => import('modules/Admin'))

const CoreLayout = ({ userRole, isLoading }) => (
  <React.Suspense fallback={<span>Loading...</span>}>
    {isLoading
      ? <Spinner styleType='fullPage' />
      : (
      <Switch>
        {userRole && (
          <Route path={ROUTES.root} component={AdminModule} />
        )}
        {!userRole && (
          <Route path={ROUTES.root} component={AuthModule} />
        )}
      </Switch>
        )
    }
  </React.Suspense>
)

export default CoreLayout
