export const getUserId = state => state.auth.id
export const getUserRole = state => state.auth.role
export const getUserFirstName = state => state.auth.firstName
export const getUserLastName = state => state.auth.lastName
export const getUserPharmacyId = state => state.auth.pharmacyId
export const getIsUserAuthError = state => state.auth.isUserAuthError
export const getLoadingStatus = state => state.auth.isLoading
export const getLogingStatus = state => state.auth.isLoging
export const getCennectionStatus = state => state.auth.isConnectionEnable
export const getUserFullName = state => `${getUserFirstName(state)} ${getUserLastName(state)}`
