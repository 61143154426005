import { auth, db } from 'services/Firebase'
import { USER_ROLES } from 'constants/auth'

const MODULE_NAME = 'token'
export const SET_USER = `${MODULE_NAME}/SET_USER`
export const CLEAN_USER = `${MODULE_NAME}/CLEAN_USER`
export const SET_USER_ERROR = `${MODULE_NAME}/SET_USER_ERROR`
export const CLEAN_USER_ERROR = `${MODULE_NAME}/CLEAN_USER_ERROR`
export const SET_LOADING = `${MODULE_NAME}/SET_LOADING`
export const CLEAN_LOADING = `${MODULE_NAME}/CLEAN_LOADIG`
export const SET_LOAGING = `${MODULE_NAME}/SET_LOADING`
export const SET_CONNECTION_STATUS = `${MODULE_NAME}/SET_CONNECTION_STATUS`

export const onLogin = value => dispatch => {
  dispatch({
    type: SET_LOAGING,
    payload: { isLoging: value },
  })
}

export const updateUser = () => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: { isLoading: true },
  })
  const user = auth.currentUser
  if (user) {
    const userId = auth.currentUser.uid
    db.collection('users').doc(userId).get()
      .then(res => {
        const { role, firstName, lastName, pharmacyId } = res.data()
        if (role !== USER_ROLES.user) {
          dispatch({
            type: SET_USER,
            payload: { role, firstName, lastName, pharmacyId, id: userId },
          })
        } else {
          auth.signOut()
          dispatch({
            type: SET_USER_ERROR,
            payload: { isUserAuthError: true },
          })
        }
        dispatch({
          type: CLEAN_LOADING,
          payload: { isLoading: false },
        })
      })
  } else {
    dispatch({
      type: CLEAN_USER,
    })
    dispatch({
      type: CLEAN_LOADING,
      payload: { isLoading: false },
    })
  }
}

export const hideUserLoginError = () => dispatch => {
  dispatch({
    type: CLEAN_USER_ERROR,
    payload: { isUserAuthError: false },
  })
}

export const setConnectionStatus = isEnable => dispatch => {
  dispatch({
    type: SET_CONNECTION_STATUS,
    payload: { isConnectionEnable: isEnable },
  })
}
