import React from 'react'
import { Spin } from 'antd'
import classNames from 'classnames/bind'
import styles from './PageSpinner.module.css'

const cx = classNames.bind(styles)

const PageSpinner = ({ styleType }) => (
  <div className={cx(
    'root',
    styleType,
  )}>
    <Spin />
  </div>
)

export default PageSpinner
