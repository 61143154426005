import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import CoreLayout from 'apps/CoreLayout'
import store from './store'

const App = () => (
  <Provider store={store} >
    <Router>
      <CoreLayout />
    </Router>
  </Provider>
)

export default App
