import { createReducer } from 'utils/reducer'
import {
  SET_USER,
  CLEAN_USER,
  SET_USER_ERROR,
  CLEAN_USER_ERROR,
  SET_LOADING,
  CLEAN_LOADING,
  SET_LOAGING,
  SET_CONNECTION_STATUS,
} from './authActions'

const initialState = {
  id: '',
  role: null,
  firstName: '',
  lastName: '',
  pharmacyId: '',
  isUserAuthError: false,
  isLoading: true,
  isLoging: false,
  isConnectionEnable: true,
}

const handlers = {
  [SET_USER]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [CLEAN_USER]: state => ({
    ...state,
    id: '',
    role: null,
    firstName: '',
    lastName: '',
    pharmacyId: '',
  }),
  [SET_USER_ERROR]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [CLEAN_USER_ERROR]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [SET_LOADING]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [CLEAN_LOADING]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [SET_LOAGING]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [SET_CONNECTION_STATUS]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}

export default createReducer(initialState, handlers)
