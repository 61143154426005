import _omit from 'lodash/omit'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import ENV from 'environments'
import { permanentMiddleware, loadState } from './middlewares/permanent'
import rootReducer from './modules/index'

const create = (initialState = loadState(), middlewares = []) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    permanentMiddleware,
    thunk,
    ...middlewares,
  ]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  let composeEnhancers = compose

  if (ENV.isDebug && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      latency: 0,
    })
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    rootReducer({}),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  )
  store.asyncReducers = {}

  store.injectReducer = reducersMap => {
    store.asyncReducers = {
      ...store.asyncReducers,
      ...reducersMap,
    }
    store.replaceReducer(rootReducer(store.asyncReducers))
  }

  store.excludeReducer = reducerName => {
    store.asyncReducers = _omit(store.asyncReducers, [reducerName])
    store.replaceReducer(rootReducer(store.asyncReducers))
  }

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  // store.unsubscribeHistory = browserHistory.listen(location(store))

  if (module.hot) {
    module.hot.accept('./modules', () => {
      const reducers = require('./modules/index')
      store.replaceReducer(reducers.makeRootReducer(store.asyncReducers))
    })
  }

  return store
}

export default create
