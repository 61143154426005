export const ROUTES = {
  root: '/',
  login: '/login',
  admin: {
    root: '/',
    users: {
      root: '/',
      userDetails: {
        root: '/user/:id',
        blister: '/user/:id/blister',
        medicationPlan: '/user/:id/medications',
        addMedication: '/user/:id/medications/add',
        physiciansNew: '/user/:id/medications/add/physicians',
        editMedication: '/user/:id/medications/edit/:medicationId',
        physiciansEdit: '/user/:id/medications/edit/:medicationId/physicians',
        details: '/user/:id/details',
        insuranceCard: '/user/:userId/insuranceCard',
        attachments: '/:pharmacyId/requests/:requestId',
      },
    },
  },
  notFound: '/404',
}
