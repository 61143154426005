import { DEFAULT_LANGUAGE } from 'constants/localization'
import { createReducer } from 'utils/reducer'
import { SET_TEXTS } from './localizationActions'

const initialState = {
  lang: DEFAULT_LANGUAGE,
  texts: {},
}

const handlers = {
  [SET_TEXTS]: (state, { payload }) => ({
    ...state,
    texts: payload,
  }),
}

export default createReducer(initialState, handlers)
