import { compose, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { auth } from 'services/Firebase'
import ENV from 'environments'
import { updateUser, onLogin } from 'store/modules/auth/authActions'
import { onLoadTexts } from 'store/modules/localization/localizationActions'
import { getUserRole, getLoadingStatus, getLogingStatus } from 'store/modules/auth/authSelectors'
import { ROUTES } from 'constants/routes'
import CoreLayout from './CoreLayout'

export default compose(
  connect(
    state => ({
      userRole: getUserRole(state),
      isLoading: getLoadingStatus(state),
      isLoging: getLogingStatus(state),
    }),
    {
      onLogin,
      onLoadTexts,
      updateUser,
    },
  ),
  withRouter,
  lifecycle({
    componentDidMount () {
      console.log(`App version: ${ENV.version} `)
      this.props.onLoadTexts()
      auth.onAuthStateChanged(this.props.updateUser)
    },
    componentDidUpdate (prevProps) {
      if (prevProps.userRole !== this.props.userRole && this.props.isLoging) {
        this.props.onLogin(false)
        this.props.history.push(ROUTES.admin.root)
      }
    },
  }),
)(CoreLayout)
