export default {
  version: process.env.REACT_APP_VERSION,
  api: {
    auth: '//cdru-dev-backend-appservice.azurewebsites.net',
    appService: '//cdru-dev-backend-appservice.azurewebsites.net',
  },
  sitepal: {
    accountId: 7146135,
  },
}
