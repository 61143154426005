import { createReducer } from 'utils/reducer'
import { SET_MENU } from './menuActions'

const initialState = {
  menuList: [],
}

const handlers = {
  [SET_MENU]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}

export default createReducer(initialState, handlers)
