import _debounce from 'lodash/debounce'

const STORE_SAVE_INTERVAL = 500
const storage = localStorage
const STORE_KEY = 'DORY'

export const loadState = () => {
  const { state = {} } = JSON.parse(storage.getItem(STORE_KEY)) || {}
  return state
}

const saveStore = _debounce(
  state => {
    storage.setItem(STORE_KEY, JSON.stringify({ state }))
  },
  STORE_SAVE_INTERVAL,
)

export const permanentMiddleware = ({ getState }) => next => action => {
  const result = next(action)
  const state = getState()
  const savedState = {
    localization: state.localization,
  }
  saveStore(savedState)
  return result
}
