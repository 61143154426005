// DoryHealh Internal Dev config
export default {
  isDebug: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCY_5rPMU2IkzQ9ZITigeJcp67Sx7xXqVU',
    authDomain: 'doryhealth-test-internal.firebaseapp.com',
    projectId: 'doryhealth-test-internal',
    storageBucket: 'doryhealth-test-internal.appspot.com',
    messagingSenderId: '105277575472',
    appId: '1:105277575472:web:e504e666945c3002ba38f5',
    measurementId: 'G-730GWDVB7S',
  },
}
