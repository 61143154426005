import { createSelector } from 'reselect'
import { MESSAGE_BY_ERROR_CODE } from 'constants/errors'

export const getData = state => state.localization
export const getLang = state => getData(state).lang
export const getTexts = createSelector(
  state => getData(state).texts,
  texts => ({
    ...texts,
    errors: {
      ...MESSAGE_BY_ERROR_CODE,
      ...texts.errors,
    },
  }),
)
