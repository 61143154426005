import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import ENV from 'environments'

// Initialize Firebase
const app = firebase.initializeApp(ENV.firebaseConfig)

export const auth = app.auth()
export const db = app.firestore()
export const storage = app.storage()

export default app
