import defaultConfig from './default'

const config = {
  ...defaultConfig,
  ...require(`./${process.env.NODE_ENV === 'test' ? 'dev' : process.env.REACT_APP_ENV}`).default,
}

const deepFreeze = obj => {
  const propNames = Object.getOwnPropertyNames(obj)

  propNames.forEach(name => {
    const prop = obj[name]

    if (typeof prop === 'object' && prop !== null) {
      deepFreeze(prop)
    }
  })

  return Object.freeze(obj)
}

export default deepFreeze(config)
