import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import authReducer from './auth/authReducer'
import menuReducer from './menu/menuReducer'
import localizationReducer from './localization/localizationReducer'
import formStatusReducer from './formStatus/formStatusReducer'

const makeRootReducer = asyncReducers => {
  return combineReducers({
    auth: authReducer,
    menu: menuReducer,
    localization: localizationReducer,
    formStatus: formStatusReducer,
    form,
    ...asyncReducers,
  })
}

export default makeRootReducer
