import { createReducer } from 'utils/reducer'
import { SET_FORM_STATUS } from './formStatusActions'

const initialState = {
  isFormChanged: false,
}

const handlers = {
  [SET_FORM_STATUS]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}

export default createReducer(initialState, handlers)
